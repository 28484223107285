<template>
    <div>
        <b-sidebar
                id="sidebar-right"
                class="px-2 pt-3"
                title="Winkelmandje"
                bg-variant="light" text-variant="dark"
                right
                v-model="visible"
        >
            <hr>
            <div v-if="page === 'cart'" class="cart">
                <div v-if="!cartSize" class="px-3 py-2">
                    <p>
                        Winkelmandje is leeg, u kunt
                        <router-link to="/bezorgen">hier</router-link>
                        bestellen.
                    </p>
                </div>
                <div v-else class="cart-container mx-3 my-3 pb-5">
                    <b-row v-for="(product, index) in cart" :key="index + product._id">
                        <b-col width="100%">
                            <b-row>
                                <b-col lg="12">
                                    <b-row class="d-flex align-items-center">
                                        <b-col cols="1" lg="1">
                                            <b-icon-trash-fill
                                                    @click="deleteFromCart(product._id, JSON.parse(product.editedRules))"
                                            />
                                        </b-col>
                                        <b-col col lg="4">
                                            <small class="font-weight-bold">{{product.quantity}}x </small>
                                            <small class="font-weight-bold">{{product.name}}</small>
                                        </b-col>
                                        <b-col col lg="3">
                                            <b-button
                                                    size="sm"
                                                    class="mr-1"
                                                    @click="removeFromCart(product._id, JSON.parse(product.editedRules))"
                                                    :disabled="product.quantity === 1"
                                                    variant="outline-danger"
                                            >
                                                <b-icon-dash/>
                                            </b-button>
                                            <b-button
                                                    size="sm"
                                                    @click="addToCart(product._id, JSON.parse(product.editedRules))"
                                                    variant="outline-success"
                                            >
                                                <b-icon-plus/>
                                            </b-button>
                                        </b-col>
                                        <b-col class="text-right">
                                            <small class="font-weight-bold">
                                                &euro; {{ ((product.price * product.quantity) / 100).toFixed(2) }}
                                            </small>
                                        </b-col>
                                    </b-row>
                                </b-col>

                                <!-- Section for menu lines -->
                                <b-col v-if="product.menu" cols="12" lg="12">
                                    <b-row v-for="(menuLine, index) in product.menu.menu_lines"
                                           :key="index + menuLine._id">
                                        <b-col col lg="11" offset="1">
                                            <small class="font-weight-bold text-muted ml-3">{{menuLine.quantity}}x
                                                {{menuLine.product.name}}</small>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <!-- Section for menu rules -->
                                <b-col v-if="product.editedRules" col lg="12">
                                    <b-row v-for="(productRule, index) in JSON.parse(product.editedRules)"
                                           :key="index + productRule._id">
                                        <b-col col lg="11" offset="1">
                                            <small class="font-weight-bold text-muted ml-3">{{productRule.quantity}}x
                                                {{productRule.name}}</small>
                                            <ul class="pl-5">
                                                <li class="row" v-for="(line, index) in formatProductRule(productRule)"
                                                    :key="index + line">
                                                    <b-col>
                                                        <small>
                                                            {{line.quantity}}x {{line.product.name}}
                                                        </small>
                                                    </b-col>
                                                    <b-col class="text-right">
                                                        <small v-if="line.product.extra_price">
                                                            + &euro; {{((line.product.extra_price * line.quantity) /
                                                            100).toFixed(2)}}
                                                        </small>
                                                    </b-col>
                                                </li>
                                            </ul>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <hr>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="d-flex" cols="12">
                            <span>Subtotaal</span>
                            <span class="ml-auto">&euro;{{(this.cartTotalAmount / 100).toFixed(2)}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="d-flex" cols="12">
                            <span>Bezorgkosten</span>
                            <span class="ml-auto">&euro;{{(this.deliveryPrice / 100).toFixed(2)}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="font-weight-bold my-1">
                        <b-col class="d-flex" cols="12">
                            <span>Totaal</span>
                            <span class="ml-auto">&euro;{{(this.totalPrice / 100).toFixed(2)}}</span>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col v-if="errorMessage" cols="12">
                            <b-alert show variant="warning">
                                {{errorMessage}}
                            </b-alert>
                        </b-col>
                        <b-col lg="12">
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                        v-model="delivery"
                                        :options="deliveryOptions"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-inline"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="delivery">
                            <b-form>
                                <b-form-group id="input-group-2" label="Postcode:" label-for="input-2">
                                    <b-form-input
                                            id="input-2"
                                            v-model="zipcode"
                                            required
                                            :disabled="!this.isOpen"
                                            placeholder="Postcode"
                                            @keydown.space.prevent
                                            description="Bijv. 1234AB"
                                    />
                                </b-form-group>
                            </b-form>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button
                                    :disabled="disabled"
                                    block
                                    variant="primary"
                                    @click="page = 'checkout'"
                            >
                                Bestellen
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <Checkout
                    class="pb-5"
                    v-if="page === 'checkout' && valid === true"
                    @togglePage="value => this.page = value"
                    :cart="this.cart"
                    :zipcode="this.zipcode"
                    :closingTime="this.closingTime"
                    :currentDate="this.currentDate"
                    :deliveryPrice="this.deliveryPrice"
                    :delivery="this.delivery"
            />
        </b-sidebar>
    </div>
</template>

<style scoped>
    #sidebar-right {
        width: 50%;
    }

    @media only screen and (max-width: 600px) {
        #sidebar-right {
            width: 100%;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        #sidebar-right {
            width: 50%;
        }
    }
</style>

<script>
    import moment from 'moment'
    import {mapState, mapGetters} from 'vuex'
    import Checkout from './Checkout'

    export default {
        name: "Cart",
        components: {
            Checkout
        },
        data: function () {
            return {
                currentDate: null,
                openingTime: moment('12:00:00', 'HH:mm:ss'),
                closingTime: moment('21:30:00', 'HH:mm:ss'),
                // closingTime: moment('20:00:00', 'HH:mm:ss'), // New years eve
                isOpen: true,
                zipcode: null,
                deliveryPrice: 200,
                minOrderPrice: null,
                page: 'cart',
                valid: false,
                errorMessage: null,
                visible: false,
                delivery: true,
                deliveryOptions: [
                    {'text': 'Bezorgen', value: true},
                    {'text': 'Afhalen', value: false},
                ]
            }
        },
        created() {
            // Fetch zipcodes
            this.$store.dispatch("fetchZipcodes")

            // Keep track of time
            setInterval(() => this.currentDate = moment.utc().format('YYYY-MM-DD HH:mm:ss'))
        },
        watch: {
            delivery() {

                this.delivery ? this.deliveryPrice = 200 : this.deliveryPrice = 0

                if(!this.delivery && this.isOpen) {
                    // Selected pick up and the restaurant is open.
                    this.zipcode = null
                    this.valid = true
                }
            },
            cartTotalAmount() {
                if (this.isOpen) {
                    if (this.delivery) {
                        // Check the zipcode
                        this.checkZipcode()
                    }
                }
            },
            zipcode() {
                if (this.isOpen && this.delivery) {
                    // Check the zipcode
                    this.checkZipcode()
                }
            },
            currentTime() {
                // Opening and closing hours
                let now = moment(this.currentTime, 'HH:mm:ss')
                if (!now.isBetween(this.openingTime, this.closingTime)) {
                    this.isOpen = false
                    this.errorMessage = "Helaas, we zijn gesloten. U kunt weer bestellen vanaf 12:00"
                } else {
                    this.isOpen = true
                }
            },
            visible(newValue) {
                // Set cart visibility in the store
                this.$store.dispatch("cartOpen", newValue);
            }
        },
        computed: {
            ...mapState([
                "cart"
            ]),
            ...mapGetters([
                "cartSize",
                "cartTotalAmount",
                "products",
                "zipcodes",
            ]),
            currentTime() {
                // Get the local current time
                let utc = moment.utc(this.currentDate).toDate()
                return moment(utc).local().format('HH:mm:ss');
            },
            totalPrice() {
                // Returns total price of the cart
                return this.cartTotalAmount + this.deliveryPrice
            },
            disabled() {
                if (this.isOpen) {

                    if (this.delivery) {

                        // Zipcode is null
                        if (!this.zipcode || !this.minOrderPrice || !this.valid) {
                            return true
                        }

                        let code = this.zipcode.substring(0, 4);
                        let characters = this.zipcode.substring(4, 6);

                        // Invalid if the code doesnt contain numbers
                        if (!code.match(/^-{0,1}\d+$/) || !characters.match(/^[a-zA-Z]+$/)) {
                            return true
                        }

                        // Check if cart total amount is bigger than the min price
                        // Will disable button if lower than Minimal order price
                        return this.cartTotalAmount < this.minOrderPrice;
                    } else {
                        // Order is a pick up!
                        // So the button will be enabled without any validation
                        return false
                    }
                } else {
                    return true
                }
            },
        },
        methods: {
            addToCart(id, editedRules = []) {
                this.$store.dispatch("addToCart", {productId: id, editedRules});
            },
            removeFromCart(id, editedRules = []) {
                this.$store.dispatch("removeFromCart", {productId: id, editedRules});
            },
            deleteFromCart(id, editedRules = []) {
                this.$store.dispatch("deleteFromCart", {productId: id, editedRules});
            },
            formatProductRule(productRules) {

                // Count all same selected products
                let result = {}

                if (productRules.quantity > 1) {
                    productRules.selected.forEach((x) => {
                        result[x] = (result[x] || 0) + 1;
                    })
                } else {
                    result[productRules.selected] = 1
                }

                let format = []
                for (const [key, value] of Object.entries(result)) {
                    format.push({
                        quantity: value,
                        product: this.products.find(product => product._id === key)
                    })
                }

                return format
            },
            checkZipcode() {

                if (this.zipcode && this.zipcode.length >= 4) {

                    // Get the code
                    let code = this.zipcode.substring(0, 4);

                    // Search through zipcodes
                    let search = this.zipcodes.find(x => x.code === code)

                    if (search) {

                        this.minOrderPrice = search.min_price
                        this.deliveryPrice = search.delivery_price

                        // Look through the rules
                        if (search.rules) {

                            // Sort the rules min_price from lower to higher
                            let sortedRules = search.rules.sort((p1, p2) => {
                                return p1.min_price - p2.min_price
                            })

                            // Set the delivery price based on the total amount if greater or equal as rule
                            sortedRules.forEach((el) => {
                                if (this.cartTotalAmount >= el.min_price) {
                                    this.deliveryPrice = el.delivery_price
                                }
                            })
                        }

                        if (this.cartTotalAmount < this.minOrderPrice) {
                            this.errorMessage = `Een minimaal bedrag van
                            \u20AC${(this.minOrderPrice / 100).toFixed(2)}
                            excl. bezorgkosten is vereist om een bestelling te doen.`
                        } else {
                            this.errorMessage = null
                        }

                        // Valid zipcode
                        this.valid = true

                    } else {
                        // Invalid zipcode
                        this.valid = false
                        this.errorMessage = "Helaas, we bezorgen niet bij jou in de buurt."
                    }
                } else {
                    // Empty zipcode
                    this.valid = false
                    this.errorMessage = null
                }
            },
            // Calculated the price of a given cart product
            productPrice(product) {

                // Add all extra_price if any
                let extra_price = JSON.parse(product.editedRules).reduce((sum, rule) => {
                    return sum + rule.extra_price
                }, 0)

                // Return the total price
                return (product.price * product.quantity) + extra_price;
            },
        }
    }
</script>
